import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks';
import { fetchExamFullNames } from '../../slices/academicClassElementSlice';
import { useLocation } from 'react-router-dom';

interface Props {
  oldValue?: string | null;
  error?: string | null;
  size?: "small" | "medium"
}

const ExamNameSelect: React.FC<Props> = ({ size="medium", oldValue, error }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { setValue, trigger } = useFormContext();
  const [resultData, setResultData] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    const dispatchedData = await dispatch(fetchExamFullNames());

    if (dispatchedData.meta.requestStatus === 'fulfilled' && dispatchedData.payload?.data) {
      setResultData(dispatchedData.payload.data);
    }
  }, [dispatch]);

  useEffect(() => {
    if (selectedValue) {
      setValue('exam_full_name', selectedValue);
      trigger('exam_full_name');
    } else {
      setValue('exam_full_name', null);
    }
  }, [selectedValue, setValue, trigger]);

  useEffect(() => {
    if (oldValue) {
      const filteredValue = resultData.find((val) => val === oldValue);
      setSelectedValue(filteredValue || null);
      setValue('exam_full_name', filteredValue || null);
    }
  }, [oldValue, resultData, setValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Autocomplete
      disabled={pathname === "/marking-schemes/update-marking-scheme" }
      size={size}
      fullWidth
      value={selectedValue}
      onChange={(event, newValue) => setSelectedValue(newValue)}
      options={resultData}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('select exam')}
          error={!!error}
          helperText={error || ''}
        />
      )}
    />
  );
};

export default ExamNameSelect;