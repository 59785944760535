export type Role = 'admin' | 'principal' | 'lead_teacher' | 'assistant_lead_teacher' | 'subject_lead_teacher' | 'class_teacher' | 'subject_teacher';
export type SchoolAccessRole =  'admin' | 'principal' | 'lead_teacher' | 'assistant_lead_teacher';
export type TeacherAccessRole = 'admin' | 'principal' | 'lead_teacher' | 'assistant_lead_teacher' | 'subject_lead_teacher'
export type CommentFormAccessRole = 'admin' | 'principal' | 'lead_teacher' | 'assistant_lead_teacher' | 'subject_lead_teacher'

// school
export const schoolPermissions: Record<SchoolAccessRole, string[]> = {
    admin: ["add", "edit", "delete", "view", "systemSettings"],
    principal: ["add", "edit", "delete", "view", "systemSettings"],
    lead_teacher: ["view", "systemSettings"],
    assistant_lead_teacher: ["view", "systemSettings"],
};

export const hasPermissionForSchool = (role: SchoolAccessRole, action: string): boolean => {
    return schoolPermissions[role]?.includes(action) || false;
};

// student
export const studentPermissions: Record<Role, string[]> = {
    admin: ["add", "edit", "delete", "view", "phoneNumber", "upload", "download"],
    principal: ["add", "edit", "delete", "view", "phoneNumber", "upload", "download"],
    lead_teacher: ["add", "edit", "delete", "view", "phoneNumber", "upload", "download"],
    assistant_lead_teacher: ["add", "edit", "delete", "view", "phoneNumber", "upload", "download"],
    subject_lead_teacher: [],
    class_teacher: ["view", "phoneNumber", "download"],
    subject_teacher: ["view"],
};

export const hasPermissionForStudent = (role: Role, action: string): boolean => {
    return studentPermissions[role]?.includes(action) || false;
};

// teacher
export const teacherPermissions: Record<TeacherAccessRole, string[]> = {
    admin: ["add", "upload", "edit", "view", "delete", "createAcc"],
    principal: ["add", "upload", "edit", "view", "delete", "createAcc"],
    lead_teacher: ["add", "upload", "edit", "view", "delete", "createAcc"],
    assistant_lead_teacher: ["add", "upload", "edit", "view", "delete", "createAcc"],
    subject_lead_teacher: [],
};

export const hasPermissionForTeacher = (role: TeacherAccessRole, action: string): boolean => {
    return teacherPermissions[role]?.includes(action) || false;
};

export const commentFormPermissions: Record<CommentFormAccessRole, string[]> = {
    admin: ["add"],
    principal: ["add"],
    lead_teacher: ["add"],
    assistant_lead_teacher: ["add"],
    subject_lead_teacher: ["add"],
};

export const hasPermissionForCommentForm = (role: TeacherAccessRole, action: string): boolean => {
    return teacherPermissions[role]?.includes(action) || false;
};