import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL, getAuth, getHeaders } from "../utils/config";
import { BasicStateTypes, CalculateRankData, CalculateScoreData, MarkingSchemeType } from "../types";
import toast from "react-hot-toast";

interface MarkingSchemeStateType extends BasicStateTypes {
  ranks: any,
}

const initialState: MarkingSchemeStateType = {
  loading: false,
  data: null,
  ranks: null,
  error: null,
};

export interface FindByExamFullNamePayload {
  student_id: number;
  school_id: number;
  academic_year_id: number;
  exam_full_name: string;
}

export interface MarkingSchemePayload {
  id?: number;
  data?: MarkingSchemeType;
}

export interface ScoreCalculationPayload {
  data: CalculateScoreData;
}

export interface RankCalculationPayload {
  data: CalculateRankData;
  pageNo?: string | null;
}

interface ThunkParams {
  method: "GET" | "POST" | "PUT" | "DELETE";
  action: string;
  payload?: MarkingSchemePayload;
  useDefaultSuccessMessage?: boolean;
}

const buildUrl = (
  baseUrl: string,
  {
    id,
    action,
    pageNo,
    classGradeId,
    classRoomId,
    schoolId,
    searchKeyword,
    academicYearId,
  }: {
    id?: number;
    action: string;
    pageNo?: string | null;
    classGradeId?: string | null;
    classRoomId?: string | null;
    schoolId?: string | null;
    searchKeyword?: string | null;
    academicYearId?: number;
  }
): string => {
  const url = new URL(`${baseUrl}/markingschemes${id ? `/${id}` : ""}/${action}`);
  if (action === "all") url.searchParams.append("per_page", "20");
  if (classGradeId) url.searchParams.append("class_grade_id", classGradeId);
  if (classRoomId) url.searchParams.append("class_room_id", classRoomId);
  if (schoolId) url.searchParams.append("school_id", schoolId);
  if (searchKeyword) url.searchParams.append("search", searchKeyword);
  if (academicYearId) url.searchParams.append("academic_year_id", academicYearId.toString());
  if (pageNo) url.searchParams.append("page", pageNo);

  return url.toString();
};

export const findByExamFullName = createAsyncThunk<
  any,
  { payload: FindByExamFullNamePayload },
  { rejectValue: string }
>(
  "markingScheme/findByExamFullName",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const url = `${BASE_URL}/markingschemes/findByExamFullName`;

      const config: AxiosRequestConfig = {
        method: "POST",
        url,
        headers,
        data: payload,
      };

      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || "An error occurred";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const calculateStudentScore = createAsyncThunk<
  any,
  { payload: ScoreCalculationPayload }, 
  { rejectValue: string }
>(
  "markingScheme/calculateScore",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const url = `${BASE_URL}/students/scores/calculate-total-average`;
      const config: AxiosRequestConfig = {
        method: "POST",
        url,
        headers,
        data: payload?.data || {},
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || "An error occurred";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const calculateRanks = createAsyncThunk<
  any,
  { payload: RankCalculationPayload }, 
  { rejectValue: string }
>(
  "markingScheme/calculateRank",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const url = `${BASE_URL}/students/calculate-rank`;
      
      const config: AxiosRequestConfig = {
        method: "POST",
        url,
        headers,
        params: { page: payload?.pageNo },
        data: payload?.data || {},
      };

      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || "An error occurred";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const markingSchemeCrud = createAsyncThunk<
  any,
  ThunkParams, 
  { rejectValue: string }
>(
  "markingScheme/markingSchemeCrud",
  async ({ method, action, payload, useDefaultSuccessMessage = true }, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const pageNo = searchParams.get("page");
      const classGradeId = searchParams.get("class_grade_id");
      const classRoomId = searchParams.get("class_room_id");
      const schoolId = searchParams.get("school_id");
      const searchKeyword = searchParams.get("keyword");
      const defaultAcademicYear = getAuth()?.defaultAcademicYear?.id;
      const academicYearId = Number(searchParams.get("academic_year_id")) || defaultAcademicYear;
      if(action === "all" && !schoolId) return;
      const url = method.toLowerCase() === "get" ? buildUrl(BASE_URL, {
        pageNo,
        id: payload?.id,
        action,
        classGradeId,
        classRoomId,
        schoolId,
        searchKeyword,
        academicYearId,
      }) : `${BASE_URL}/markingschemes${payload?.id ? `/${payload.id}` : ""}/${action}`;
      const headers = getHeaders();

      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        data: payload?.data || {},
      };

      const response = await axios(config);
      if (useDefaultSuccessMessage && action === "delete") toast.success("Successfully deleted", { duration: 3000 });

      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || "An error occurred";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// Redux slice
export const markingSchemeSlice = createSlice({
  name: "markingScheme",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(markingSchemeCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(markingSchemeCrud.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(markingSchemeCrud.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to perform operation";
      })
      .addCase(calculateStudentScore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(calculateStudentScore.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(calculateStudentScore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to calculate student score";
      })
      .addCase(calculateRanks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(calculateRanks.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.ranks = action.payload || [];
      })
      .addCase(calculateRanks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to calculate ranks";
      })
      .addCase(findByExamFullName.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(findByExamFullName.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(findByExamFullName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch marking scheme by exam full name";
      });
  },
});

export default markingSchemeSlice.reducer;