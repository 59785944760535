import { AcademicYearType } from "../types";

const BASE_URL = "https://miaprivateschool.com/uoc/api";
//const BASE_URL = "https://miaschool.uoctech.com/uoc/api";
const authName = 'MiaSchoolAuth';

export interface AuthData {
    id: number;
    name: string;
    email: string;
    accessToken: string;
    role: string;
    defaultAcademicYear?: AcademicYearType;
    subjects: {
        id: number,
        name: string,
    }[]
}

const setAuth = (data: AuthData): void => {
    localStorage.setItem(authName, JSON.stringify(data));
}

const removeAuth = (): void => {
    localStorage.removeItem(authName);
}

const getAuth = (): AuthData | null => {
    const auth = localStorage.getItem(authName);
    if (auth) {
        return JSON.parse(auth);
    }
    return null;
}

const getHeaders = (): { [key: string]: string } => {
    const auth = getAuth();
    return {
        'Content-Type': 'application/json',
        Authorization: auth ? `Bearer ${auth.accessToken}` : '',
    };
}

const getSelectedLng = ()=>{
    return localStorage.getItem("miaLng");
}

export { getSelectedLng, authName, BASE_URL, getHeaders, setAuth, getAuth, removeAuth };
